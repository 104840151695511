.container-text {
  display: block;
  position: absolute;
  top: 35%;
  right: 50;
  text-align: center;
  width: 100%;
  font-weight: 600;
}

.main-title {
  color: white;
  text-transform: capitalize;
  gap: 10px;
  margin-bottom: 2.5rem;
}

.title-hero { 
  text-transform: uppercase;
  border-left: 2px solid red;
  font-size: 1.6rem;
  padding: 0 10px;
  color: white;
}

.title-hero:last-child{
  border-left: 2px solid red;
}

.text-hero {
  color: white;
  line-height: 1.8rem;
  font-size: 1.2rem;
  width: 50%;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {

  .main-title {
    width: 50%;
    margin: 0 auto;
  }

  .title-hero {
    display: flex;
    /* width: fit-content; */
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    letter-spacing: 2px;
    line-height: 1.5rem;
  }
  .title-hero:last-child{
    border-left: 2px solid red;
  }
  .text-hero {
    color: white;
    line-height: 1.2rem;
    font-size: 1rem;
    width: 80%;
    margin: 0 auto;
    padding-top: 2rem;
  }

  .container-text {
    display: block;
    position: absolute;
    top: 33%;
    right: 50;
    text-align: center;
    width: 100%;
    font-weight: 600;
  }
  
}
