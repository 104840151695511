.filter {
  filter: brightness(0.5);
}

.inaltime {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.inaltime img {
  max-width: none;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
