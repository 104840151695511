@import url('https://fonts.googleapis.com/css2?family=Karla:wght@200&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Karla', sans-serif;
}

